import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import DataSourceCharacteristicsDto from "../classes/dtos/datasourcemodule/DataSourceCharacteristicsDto";

const DataSourceCharacteristicsApi = {

    GetDataSourceCharacteristicsByIdAsync: async function (id: number): Promise<ProcessingResult<DataSourceCharacteristicsDto>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unauthorized");
        }

        var parameters = "&dataSourceId=" + id;

        const response = await fetch(Settings.api_url + "/DataSourceCharacteristics/GetDataSourceCharacteristicsById?customerId=" + customerId.toString() + parameters, {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();

            return ProcessingResult.Ok(new DataSourceCharacteristicsDto(data.deleteAvailable));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default DataSourceCharacteristicsApi;