import React, { useState, useEffect, useCallback } from 'react';

import { CheckBox } from 'devextreme-react/check-box';

import DataSourceCharacteristicsApi from '../../api/DataSourceCharacteristicsApi';

import DataSourceCharacteristicsDto from '../../classes/dtos/datasourcemodule/DataSourceCharacteristicsDto';
import PathSynchroSetupDto from '../../classes/dtos/pathsynchromodule/PathSynchroSetupDto';

import './SynchroSetup.css';

interface SynchroSetupProps {
    PathId: number;
    DataSourceTargetId: number | null;
    PathSynchroSetup: PathSynchroSetupDto | null;
    SetPathSynchroSetup(value: PathSynchroSetupDto): void;
}

const SynchroSetup: React.FC<SynchroSetupProps> = ({ PathId, DataSourceTargetId, PathSynchroSetup, SetPathSynchroSetup }) => {

    const [dataSourceCharacteristics, setDataSourceCharacteristics] = useState<DataSourceCharacteristicsDto | null>(null);

    const dataSourceCharacteristicsLoad = useCallback(async () => {
        if (!dataSourceCharacteristics) {

            if (DataSourceTargetId === null) {
                setDataSourceCharacteristics(new DataSourceCharacteristicsDto(false));
                return;
            }

            DataSourceCharacteristicsApi.GetDataSourceCharacteristicsByIdAsync(DataSourceTargetId)
                .then(getDataSourceCharacteristicsResult => {
                    setDataSourceCharacteristics(getDataSourceCharacteristicsResult.Result);
                });
        }

    }, [dataSourceCharacteristics, DataSourceTargetId]);

    useEffect(() => {
        dataSourceCharacteristicsLoad()
    }, [dataSourceCharacteristicsLoad]);

    const onValueChanged = useCallback((e) => {
        let dto = PathSynchroSetup;
        if (dto !== null) {
            dto.DeletionActivated = e.value ?? false;
        }
        SetPathSynchroSetup(dto);
    }, []);

    let deletionEnabled = dataSourceCharacteristics?.DeleteAvailable ?? false;

    return (
        <div className='BlockCard'>
            <div className='BlockHeader'>
                <div className='Deletion'>
                    <CheckBox disabled={!deletionEnabled} value={PathSynchroSetup?.DeletionActivated} onValueChanged={onValueChanged} />
                    <span>
                        {deletionEnabled ? ("Data deletion authorized") : ("The target doesn't manage the deletion")}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default SynchroSetup;