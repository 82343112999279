import React, { useState, useEffect, useCallback } from 'react';
import { Button, ProgressBar } from 'devextreme-react';

import PathSynchroLogic from '../../logics/pathsynchromodule/PathSynchroLogic';

import './SynchroResume.css';

interface SynchroResumeProps {
    PathId: number;
}

const SynchroResume: React.FC<SynchroResumeProps> = ({ PathId }) => {

    const [syncToDo, setSyncToDo] = useState(false);
    const [progress, setProgress] = useState(0);
    const [syncDone, setSyncDone] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const synchro = useCallback(async () => {
        if (syncToDo === true) {
            setErrorMessage(null);

            let result = await PathSynchroLogic.PathSynchroAsync(PathId, (progress) => { setProgress(progress); });

            if (result.IsFailed()) {
                alert(result.ErrorMessage);
            }

            setErrorMessage(result.Result);
            setSyncDone(true);
            setSyncToDo(false);
        }
    }, [PathId, syncToDo]);

    useEffect(() => {
        synchro()
    }, [synchro]);

    const synchroAction = async () => {
        setSyncDone(false);
        setProgress(0);
        setSyncToDo(true);
    }

    return (
        <div className='BlockCard'>
            <div className='BlockHeader'>
                <Button onClick={synchroAction} disabled={syncToDo} type="default" stylingMode="contained">Synchronize</Button>
            {/*    {!props.syncAvailable && <Label marginLeft='5px' color='red'>The keys was not setup correctly</Label>}*/}
                <div className="ProgressContainer">
                    {(syncToDo || syncDone) && <ProgressBar color="primary" value={progress} width='100%' />}
                </div>
            </div>
        {/*    {(errorMessage != null && errorMessage !== "") && <Alert color="danger">Error during execution : {errorMessage}</Alert>}*/}
        </div>
    );
}

export default SynchroResume;