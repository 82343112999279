class DataSourceCharacteristicsDto {
    public DeleteAvailable: boolean;

    constructor(
        deleteAvailable: boolean
    ) {
        this.DeleteAvailable = deleteAvailable;
    }
}

export default DataSourceCharacteristicsDto;
